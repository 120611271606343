import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import EarnItComponent from "../../components/EarnIt/EarnIt"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router"
import {fetchAdminTasks} from '../../store/fetchAdminTasks'

function Customers() {
  const dispatch = useDispatch()
  const history = useNavigate()
  const earnit = useSelector(state=> state.tasks.value)
  const loading = useSelector(state=>state.tasks.loading)

  React.useEffect(()=>{
    window.scrollTo(0,0)
    if(sessionStorage.getItem("adminToken")==null){
      history("/")
    }
  },[history])

  React.useEffect(()=>{
    dispatch(fetchAdminTasks())
  },[dispatch])

  React.useEffect(()=>{
    window.scrollTo(0,0)
  },[])


  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <EarnItComponent earnit={earnit} loading={loading}/>
        </div>
    
    </div>
  )
}

export default Customers