import {
    Button,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    // useDisclosure,
    Box,
  } from '@chakra-ui/react';
  import React from 'react';
  import axios from 'axios';
  // import Swal from 'sweetalert2';
  import BasicInformationTable from './Tables/BasicInformationTable';
import Swal from 'sweetalert2';
  
  function ViewUser(props) {
  
    return (
      <div>
        <div>
          <BasicInformationTable id={'basic'} user={props.user} />
        </div>
      </div>
    );
  }
  
  function CustomersTable(props) {
    const [viewOpen, setViewOpen] = React.useState(false);
    const [actionOpen, setActionOpen] = React.useState(false);
    const [action, setAction] = React.useState('')
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const [loading,setLoading] = React.useState(false)
  
    // const deleteUser = () => {
    //   onOpen();
    // };
  
    const resendInvite = (payload) =>{
      //resend invite
      console.log(payload)
     setLoading(true)
      axios({
        method:"POST",
        url:process.env.REACT_APP_API_URL+"/admin-resend-invite",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
          ContentType:"application/json",
          Accept:"*/*"
        },
        data:payload

      }).then((response)=>{
        setLoading(false)
        console.log(response)
        Swal.fire({
          icon:"success",
          title:"Invite Resent Successfully",
          text:"You have successfully resent an invite to "+payload.name,
          confirmButtonColor:"#0066f5"
        })

      })
    }
  
    const onViewClose = () => {
      setViewOpen(false);
    };
  
    const confirmAction = action => {
      setAction(action);
      setViewOpen(false);
      setActionOpen(true);
    };
  
    const onActionClose = () => {
      setActionOpen(false);
    };

  
    return (
      <tr>
        <td>{props.user.name}</td>
        <td>
           {props.user.email}
        </td>
        <td>{props.user.phone}</td>
        <td>
          <div className={props.user.status===undefined?'Pending':props.user.status + ' w-24'}>
            <li>{props.user.status===undefined?'Pending':props.user.status}</li>
          </div>
        </td>
        <td className="flex space-x-3 mt-1">
          <Button isLoading={loading} disabled={props.user.status===undefined?false:true} rounded={"full"} size={"sm"} borderColor={"#005FEB"} color={"#005FEB"} onClick={()=>resendInvite(props.user)} variant={"outline"}>
           Resend Invite
          </Button>
        </td>
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          // isOpen={isOpen}
          // onClose={onClose}
        >
          <ModalOverlay />
          <Box pos="absolute" top={17} right={407}>
            <ModalCloseButton />
          </Box>
          <ModalContent>
            <ModalHeader>
              <h1>Delete {props.user.firstName}</h1>
            </ModalHeader>
  
            <ModalBody pb={6}>
              <p>
                Are you sure you want to delete {props.user.firstName}{' '}
                {props.user.lastName}'s account? This deletion is permanent and
                the user's account would not be retrievable
              </p>
  
              <div className="text-end my-5">
                <Button
                  padding={'0px 20px'}
                  // onClick={onClose}
                  color="white"
                  rounded="full"
                  variant={'black'}
                  background="black"
                  className="mr-2"
                >
                  No,don't delete
                </Button>
                <Button
                  // onClick={confirmDeleteUser}
                  color="white"
                  rounded="full"
                  variant={'#ff0000'}
                  background="#ff0000"
                  // isLoading={btnloading}
                >
                  Yes, Delete
                </Button>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
  
        {/* modal for view all users details */}
        <Modal isOpen={viewOpen} onClose={onViewClose} size={'5xl'}>
          <ModalOverlay />
          <ModalContent>
            <Box pos="absolute" top={0} right={0}>
              <ModalCloseButton />
            </Box>
            <ModalHeader>
              <h1 className="text-center">User Information</h1>
            </ModalHeader>
            <hr />
  
            <ViewUser user={props.user} />
  
            <ModalBody pb={6}>
              <div className="text-end my-5">
                <Button
                  padding={'0px 20px'}
                  onClick={() => confirmAction(props.user.status ==='suspended'?"unsuspended":"suspended")}
                  color="red"
                  rounded="full"
                  variant={'unstyled'}
                  className="mr-2"
                >
                  {props.user.status === 'suspended' ? ("Unsuspend "+ props.user.firstName):("Suspend "+ props.user.firstName)}
                </Button>
                <Button
                  onClick={() => confirmAction(props.user.status ==='verified'?"Unverify":"Verify")}
                  color="white"
                  rounded="full"
                  variant={'#005FEB'}
                  background="#005FEB"
                >
                  {props.user.status === 'verified' ? ("Unverify "+ props.user.firstName):("Verify "+ props.user.firstName)}
                </Button>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
  
        <Modal isOpen={actionOpen} onClose={onActionClose}>
          <ModalOverlay />
          <ModalContent>
            <Box pos="absolute" top={0} right={0}>
              <ModalCloseButton />
            </Box>
            <ModalHeader>
              <h1 className="text-center">
                {' '}
                {(action === 'suspended' || action ==='unsuspended') ? action.slice(0,-2):action } {props.user.firstName}{' '}
              </h1>
            </ModalHeader>
            <hr />
  
            <ModalBody pb={6}>
              <p className="text-center mt-5">
                Are you sure you want to  {(action === 'suspended' || action ==='unsuspended') ? action.slice(0,-2):action }{' '}
                {props.user.firstName}?
              </p>
  
              <div className="text-end my-5">
                <Button
                  padding={'0px 20px'}
                  onClick={onActionClose}
                  color="red"
                  rounded="full"
                  variant={'unstyled'}
                  className="mr-2"
                >
                  No,close
                </Button>
                <Button
                  // onClick={confirmDelete}
                  color="white"
                  rounded="full"
                  variant={'#005FEB'}
                  background={"#0066f5"}
                  // isLoading={btnloading}
                >
                  Yes,  {(action === 'suspended' || action ==='unsuspended') ? action.slice(0,-2):action } {props.user.firstName}
                </Button>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      
      </tr>
    );
  }
  
  export default CustomersTable;
  