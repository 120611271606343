import React from 'react'

function Tabs(props) {

  const filterAll = () => {
    document.getElementById("allTransactions").classList.add("activeTab")
    document.getElementById("depositTransactions").classList.remove("activeTab")
    document.getElementById("withdrawalTransactions").classList.remove("activeTab")
    document.getElementById("transferTransactions").classList.remove("activeTab")
    document.getElementById("successfulTransactions").classList.remove("activeTab")
    document.getElementById("pendingTransactions").classList.remove("activeTab")
    document.getElementById("failedTransactions").classList.remove("activeTab")
    props.filterAll();
  };

  const filterDeposit = () => {
    document.getElementById("allTransactions").classList.remove("activeTab")
    document.getElementById("depositTransactions").classList.add("activeTab")
    document.getElementById("withdrawalTransactions").classList.remove("activeTab")
    document.getElementById("transferTransactions").classList.remove("activeTab")
    document.getElementById("successfulTransactions").classList.remove("activeTab")
    document.getElementById("pendingTransactions").classList.remove("activeTab")
    document.getElementById("failedTransactions").classList.remove("activeTab")
    props.filterDeposit();
  };

  const filterWithdrawal = () => {
    document.getElementById("allTransactions").classList.remove("activeTab")
    document.getElementById("depositTransactions").classList.remove("activeTab")
    document.getElementById("withdrawalTransactions").classList.add("activeTab")
    document.getElementById("transferTransactions").classList.remove("activeTab")
    document.getElementById("successfulTransactions").classList.remove("activeTab")
    document.getElementById("pendingTransactions").classList.remove("activeTab")
    document.getElementById("failedTransactions").classList.remove("activeTab")
    props.filterWithdrawal();
  };

  const filterTransfer = () => {
    document.getElementById("allTransactions").classList.remove("activeTab")
    document.getElementById("depositTransactions").classList.remove("activeTab")
    document.getElementById("withdrawalTransactions").classList.remove("activeTab")
    document.getElementById("transferTransactions").classList.add("activeTab")
    document.getElementById("successfulTransactions").classList.remove("activeTab")
    document.getElementById("pendingTransactions").classList.remove("activeTab")
    document.getElementById("failedTransactions").classList.remove("activeTab")
    props.filterTransfer();
  };

  const filterSuccessful = () => {
    document.getElementById("allTransactions").classList.remove("activeTab")
    document.getElementById("depositTransactions").classList.remove("activeTab")
    document.getElementById("withdrawalTransactions").classList.remove("activeTab")
    document.getElementById("transferTransactions").classList.remove("activeTab")
    document.getElementById("successfulTransactions").classList.add("activeTab")
    document.getElementById("pendingTransactions").classList.remove("activeTab")
    document.getElementById("failedTransactions").classList.remove("activeTab")
    props.filterSuccessful();
  };


  const filterPending = () => {
    document.getElementById("allTransactions").classList.remove("activeTab")
    document.getElementById("depositTransactions").classList.remove("activeTab")
    document.getElementById("withdrawalTransactions").classList.remove("activeTab")
    document.getElementById("transferTransactions").classList.remove("activeTab")
    document.getElementById("successfulTransactions").classList.remove("activeTab")
    document.getElementById("pendingTransactions").classList.add("activeTab")
    document.getElementById("failedTransactions").classList.remove("activeTab")
    props.filterPending();
  };

  const filterFailed = () => {
    document.getElementById("allTransactions").classList.remove("activeTab")
    document.getElementById("depositTransactions").classList.remove("activeTab")
    document.getElementById("withdrawalTransactions").classList.remove("activeTab")
    document.getElementById("transferTransactions").classList.remove("activeTab")
    document.getElementById("successfulTransactions").classList.remove("activeTab")
    document.getElementById("pendingTransactions").classList.remove("activeTab")
    document.getElementById("failedTransactions").classList.add("activeTab")
    props.filterFailed();
  };

  return (
    <div className="flex mt-2 cursor-pointer">
        <div id="allTransactions" onClick={filterAll} className="mr-3 activeTab">All</div>
        <div id="depositTransactions" onClick={filterDeposit} className="mr-4">Deposit</div>
        <div id="withdrawalTransactions" onClick={filterWithdrawal} className="mr-4">Withdrawal</div>
        <div id="transferTransactions" onClick={filterTransfer} className="mr-4">Transfer</div>
        <div id="successfulTransactions" onClick={filterSuccessful} className="mr-4">Successful</div>
        <div id="pendingTransactions" onClick={filterPending} className="mr-4">Pending</div>
        <div id="failedTransactions" onClick={filterFailed} className="mr-4">Failed</div>
    </div>
  )
}

export default Tabs