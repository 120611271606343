import React from 'react';

function Tabs(props) {
  const filterKid = () => {
    document.getElementById("all").classList.remove("activeTab")
    document.getElementById("parent").classList.remove("activeTab")
    document.getElementById("kid").classList.add("activeTab")
    props.filterKid();
  };
  const filterParent = () => {
    document.getElementById("all").classList.remove("activeTab")
    document.getElementById("kid").classList.remove("activeTab")
    document.getElementById("parent").classList.add("activeTab")
    props.filterParent();
  };

  const filterAll = () => {
    document.getElementById("parent").classList.remove("activeTab")
    document.getElementById("kid").classList.remove("activeTab")
    document.getElementById("all").classList.add("activeTab")
    props.filterAll();
  };
  return (
    <div className="flex mt-2 cursor-pointer">
      <div id="all" onClick={filterAll} className="mr-5 activeTab">
        All
      </div>
      <div id="kid" onClick={()=>filterKid()} className="mr-5">
        Kids
      </div>
      <div id="parent" onClick={()=>filterParent()}>Parents</div>
    </div>
  );
}

export default Tabs;
