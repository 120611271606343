import { Table, Thead, Tr,Th,Tbody } from '@chakra-ui/react'
import React from 'react'

function AccountInformationTable(props) {
  return (
    <div id={props.id} className="tableWidth">
      <Table>
        <Thead>
          <Tr>
            <Th>S/N</Th>
            <Th>Information</Th>
            <Th>Inputs</Th>
          </Tr>
        </Thead>

        <Tbody>
          <tr>
            <td>1</td>
            <td>First Name</td>
            <td>{props.user.firstName}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Last Name</td>
            <td>{props.user.lastName}</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Minietag</td>
            <td>@{props.user.minietag}</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Email Address</td>
            <td>{props.user.email}</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Phone Number</td>
            <td>{props.user.phone}</td>
          </tr>

          <tr>
            <td>6</td>
            <td>Date of Birth</td>
            <td>{props.user.dob}</td>
          </tr>

          <tr>
            <td>7</td>
            <td>Gender</td>
            <td>{props.user.gender}</td>
          </tr>
        </Tbody>
      </Table>
    </div>
  )
}

export default AccountInformationTable