import React,{useState} from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import CustomersComponent from "../../components/Customers/Customers"
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import { fetchAdminStats,setPage } from '../../store/fetchAdminStat'
import { fetchAdminOverviews } from '../../store/fetchAdminOverview';
import {useNavigate} from "react-router"
import Pagination from '../../components/Customers/Pagination'
import { setSearchUsers } from '../../store/fetchAdminStat'

function Customers() {
  const dispatch = useDispatch()
  const users = useSelector((state) => state.adminStat.value)
  const history = useNavigate()
  const loading= useSelector((state) => state.adminStat.loading)
  const overviews = useSelector(state => state.adminOverview.value);
  const page = useSelector(state => state.adminStat.page);
  const [search,setSearch] = useState("")

  React.useEffect(()=>{
    dispatch(setSearchUsers(search))
    dispatch(fetchAdminStats(page))
    dispatch(fetchAdminOverviews())
  },[page,dispatch,search])


React.useEffect(()=>{
  window.scrollTo(0,0)
  if(sessionStorage.getItem("adminToken")==null){
    history("/")
  }
},[history])


  const handlePageClick = (page) =>{
    window.scrollTo(0,0)
    dispatch(setPage(page))
  }

  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <CustomersComponent users={users} loading={loading} setSearch={setSearch}/>
            <Pagination overviews={overviews} handlePageClick={handlePageClick} page={page}/>
        </div>
    
    </div>
  )
}

export default Customers