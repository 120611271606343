import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import InviteUserComponent from "../../components/InviteUser/InviteUser"
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router"
import { fetchEventUsers } from '../../store/fetchEventUser'


function Withdrawal() {
  const events = useSelector((state) => state.events.value.events)
  const dispatch = useDispatch()
  const history = useNavigate()
  const loading = useSelector((state) => state.events.loading)

  React.useEffect(()=>{
    dispatch(fetchEventUsers())
  },[dispatch])


React.useEffect(()=>{
  window.scrollTo(0,0)
  if(sessionStorage.getItem("adminToken")==null){
    history("/")
  }
},[history])


  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <InviteUserComponent users={events} loading={loading}/>
        </div>
    
    </div>
  )
}

export default Withdrawal