import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import CustomersComponent from "../../components/Kolosave/Customers"
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import { fetchKolosaves } from '../../store/fetchKolosave'
import {useNavigate} from "react-router"


function Customers() {
  const kolosave = useSelector((state) => state.kolosave.value)
  const dispatch = useDispatch()
  const history = useNavigate()
  const loading = useSelector((state) => state.kolosave.loading)

  React.useEffect(()=>{
    dispatch(fetchKolosaves())
  },[dispatch])


React.useEffect(()=>{
  window.scrollTo(0,0)
  if(sessionStorage.getItem("adminToken")==null){
    history("/")
  }
},[history])


  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <CustomersComponent users={kolosave} loading={loading}/>
        </div>
    
    </div>
  )
}

export default Customers