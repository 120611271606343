import React,{useState} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import './Dashboard.css';
import { InputGroup, InputLeftElement, Input, Button } from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import Tabs from './Tabs';
import Overview from './Overview';
import { CSVDownload } from "react-csv";
import { useDispatch,useSelector } from 'react-redux';
import { setLimit } from '../../store/fetchDataExport';
import { fetchAdminDatas } from '../../store/fetchDataExport';

function Dashboard(props) {
  const data = useSelector((state)=>state.data.value)
  const dispatch = useDispatch()
  const [loading,setLoading] = useState(false)
  const [total,setTotal] = useState("")

  //set the data limit to the max available
  const prepareData = () =>{
    setLoading(true)
    setTotal(props.overviews.adult + props.overviews.youngster)
    dispatch(setLimit(total))
    //fetch all the users
    dispatch((fetchAdminDatas(total))).then(()=>{
      setLoading(false)
    })
  }

  return (
    <div className="dashboardContainer">
      <PageHeading title="Dashboard" />
      <hr />

      <div className="dashboardContent">
        <Tabs />

        <div className="flex space-x-3">
          <InputGroup width={'230px'}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input placeholder="Search" />
          </InputGroup>

          <Input width={'200px'} type={'date'} />

          <Button isLoading={loading} onClick={prepareData} variant={'outline'}>
            <DownloadIcon color={'#005FEB'} />
            <span className="text-blue-600 ml-2">Export Data </span>
          </Button>
          {data && total && <CSVDownload data={data} />}
        </div>
      </div>

      <hr />

      <Overview
        paystackDetails={props.paystackDetails}
        vfdDetails={props.vfdDetails}
        stats={props.stats}
        loading={props.loading}
        earnit={props.earnit}
        transactions={props.transactions}
        overview={props.overviews}
        allowance={props.allowance}
      />
    </div>
  );
}

export default Dashboard;
