import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
// import { ColorModeSwitcher } from './ColorModeSwitcher';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import {Provider} from "react-redux"
import {store} from "./store/store"
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Customers from "./pages/Customers/Customers"
import Transactions from "./pages/Transactions/Transaction"
import EarnIt from './pages/EarnIt/EarnIt';
import Report from "./pages/Report/Report"
import Roles from "./pages/Roles/Roles"
import Settings from "./pages/Settings/Settings"
import Cards from './pages/Cards/Card';
import Withdrawal from "./pages/Withdrawal/Withdrawal"
import InviteUser from "./pages/InviteUser/InviteUser"
import InvitedParent from "./pages/InvitedParent/InvitedParent"
import Kolosave from "./pages/Kolosave/Kolosave"
import Allowance from './pages/Allowance/Allowance';

function App() {
  return (
    <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Provider store={store}>
    <Routes>
      <Route path="/" element={<Login />}/>
      <Route path="/login" element={<Login />}/>
      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/customers" element={<Customers />}/>
      <Route path="/transactions" element={<Transactions />}/>
      <Route path="/earnit" element={<EarnIt />}/>
      <Route path="/report" element={<Report />}/>
      <Route path="/roles" element={<Roles />}/>
      <Route path="/settings" element={<Settings />}/>
      <Route path="/card" element={<Cards />}/>
      <Route path="/withdrawal" element={<Withdrawal />}/>
      <Route path="/invite-users" element={<InviteUser />}/>
      <Route path="/invite-parents" element={<InvitedParent />}/>
      <Route path="/kolosave" element={<Kolosave />}/>
      <Route path="/allowance" element={<Allowance/>}/>
      </Routes>
      </Provider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
