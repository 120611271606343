import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import './Customers.css';
import { InputGroup, InputLeftElement, Input,Table,Tr,Th,Thead,Tbody, Spinner } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Tabs from './CustomerTab';
import CustomersTable from './CustomersTable';
import CustomerTableLoading from './CustomerTableLoading';

function Dashboard(props) {
  const [search,setSearch] = React.useState("")
  const [users,setusers] = React.useState("")
  const [usersLoading] = React.useState([
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'fund',
      amount: '90000',
      accountType:"youngster",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
  ]);

 
  

  const filterAll = () =>{
    setSearch('')

  }

 const searchUser = (e) => {
    props.setSearch(e)
    sessionStorage.setItem("search",e)
  }

  const filterKid = () =>{
    setSearch("youngster")
    setusers(props.users.filter(
      (user) => user.firstName.toLowerCase().indexOf('youngster') > -1 || 
      user.lastName.toLowerCase().indexOf('youngster') > -1 ||
      user.email.toLowerCase().indexOf('youngster') > -1  ||
      user.accountType.toLowerCase().indexOf('youngster') > -1 
    ))
  }

  const filterParent = () =>{
    setSearch("adult")
    setusers(props.users.filter(
      (user) => user.firstName.toLowerCase().indexOf('adult') > -1 || 
      user.lastName.toLowerCase().indexOf('adult') > -1 ||
      user.email.toLowerCase().indexOf('adult') > -1  ||
      user.accountType.toLowerCase().indexOf('adult') > -1 
    ))
  }

 

  return (
    <div className="dashboardContainer">
      <PageHeading title="Customer Management" />
      <hr />

      <div className="dashboardContent">
        <Tabs filterKid={filterKid} filterAll={filterAll} filterParent={filterParent} />

        <div className="flex space-x-3">
          <InputGroup width={'230px'}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input onKeyUp={(e)=>searchUser(e.target.value)}  placeholder="Search" />

            {props.loading && <Spinner></Spinner>}
          </InputGroup>

          <Input width={'200px'} type={'date'} />
        </div>
      </div>

      <hr />

      <div className="customerTable">
        <Table>
          <Thead>
            <Tr>
              <Th textTransform={"none"}>Account Name</Th>
              <Th>Email Address</Th>
              <Th>Account Type</Th>
              <Th>Date Registered</Th>
              <Th>Balance</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.loading && usersLoading.map((user, i) => (
              <CustomerTableLoading user={user} key={i} />
            ))}
            </Tbody>
          <Tbody>
            {search === ''  && props.users && props.users.map((user, i) => (
              <CustomersTable user={user} key={i} />
            ))}

            {search && users && users.map((user, i) => (
              <CustomersTable user={user} key={i} />
            ))}
            </Tbody>

           
        </Table>
      </div>
    </div>
  );
}

export default Dashboard;
