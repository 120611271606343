import { DownloadIcon } from '@chakra-ui/icons'
import { Table, Thead, Tr,Th,Tbody,Button } from '@chakra-ui/react'
import React from 'react'
import axios from 'axios'
import {toast,ToastContainer} from "react-toastify"
import {unslugify} from "unslugify"



function AccountInformationTable(props) {
  

  const downloadFile = (file) =>{
    window.open(file,"_blank")
  }

  const approve = (kyc) =>{
    toast.loading ("Updating. Please wait...")
    if(kyc.status === 'pending')
    {
      axios({
        method:"POST",
        url:process.env.REACT_APP_API_URL+"/admin/update-kyc",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
          ContentType:"application/json"
        },
        data:{
          userId:props.user._id,
          accountType:props.user.accountType,
          kyc:kyc,
          firstName:props.user.firstName,
          email:props.user.email,
          status:"verified"
        }

      }).then((response)=>{
        console.log(response)
        window.location.reload()
      }).catch((error)=>{
        toast.dismiss()
        alert(error)
      })
    }

    else  {
      axios({
        method:"POST",
        url:process.env.REACT_APP_API_URL+"/admin/update-kyc",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
          ContentType:"application/json"
        },
        data:{
          userId:props.user._id,
          accountType:props.user.accountType,
          kyc:kyc,
          firstName:props.user.firstName,
          email:props.user.email,
          status:"pending"
        }

      }).then((response)=>{
        console.log(response)
        console.log(response)
        window.location.reload()
      }).catch((error)=>{
        toast.dismiss()
        alert(error)
      })
    }
  }


  return (
    <div id={props.id} className="hiddenForm tableWidth">
      <ToastContainer />
      <Table>
        <Thead>
          <Tr>
            <Th>S/N</Th>
            <Th>Document Type</Th>
            <Th>Date Uploaded</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>

        {props.user.kyc && props.user.kyc.length !== 0 && <Tbody>
            {props.user.kyc.map((kyc,i)=>(
               <tr key={i}>
               <td>{i+1}</td>
               <td>{unslugify(kyc.type)} </td>
               <td>{kyc.status}</td>
               <td><div className={kyc.status+" flex justify-center"}>{kyc.status}</div></td>
               <td><DownloadIcon cursor={"pointer"} onClick={()=>downloadFile(kyc.file)}/>
               {kyc.status==='pending'?<Button onClick={()=>approve(kyc)} margin={"0px 0px 0px 20px"} background="#005FEB" rounded={"full"} color={"white"} variant={"#005FEB"}>Approve</Button>:<Button onClick={()=>approve(kyc)} margin={"0px -20px 0px 25px"} borderColor={"#005FEB"} rounded={"full"} color={"#005FEB"} variant={'outline'}>Disapprove</Button>}
               
               </td>
             </tr>
            ))}
         
          

        
        </Tbody>}
      </Table>
    </div>
  )
}

export default AccountInformationTable