import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  value: "",
  loading: false,
};


export const fetchAdminOverviews = createAsyncThunk("admin/fetchAdminOverview", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-stats",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
      return response.data.data
    } catch (error) {
      return error;
    }
  });
  
  export const fetchAdminOverview = createSlice({
    name: "adminOverview",
    initialState,
    extraReducers: {
      [fetchAdminOverviews.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchAdminOverviews.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchAdminOverviews.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchAdminOverview.reducer;