import React from 'react'

function Tabs() {
  return (
    <div className="flex mt-2">
        <div className="mr-6 activeTab">Invite Admin</div>
        <div className="mr-6">Create Roles</div>
        <div className="mr-6">Add Permission</div>
    </div>
  )
}

export default Tabs