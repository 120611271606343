import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  value: "",
  loading: false,
};


export const fetchAllowances = createAsyncThunk("task/fetchAllowance", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-allowance",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data
    } catch (error) {
      return error;
    }
  });
  
  export const fetchAllowance = createSlice({
    name: "allowance",
    initialState,
    extraReducers: {
      [fetchAllowances.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchAllowances.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchAllowances.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchAllowance.reducer;