import React from 'react';
import { Button } from '@chakra-ui/react';

function PrimaryBtn({ title, bgcolor, variant, onClick, color,rounded,border }) {
  return (
    <Button onClick={onClick} borderColor={border} bg={bgcolor} color={color} variant={variant} rounded={rounded}>
      {title}
    </Button>
  );
}

export default PrimaryBtn;
