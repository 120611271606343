import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import CustomersComponent from "../../components/Allowance/Customers"
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import { fetchAllowances } from '../../store/fetchAllowance'
import {useNavigate} from "react-router"


function Allowance() {
  const allowance = useSelector((state) => state.allowance.value)
  const dispatch = useDispatch()
  const history = useNavigate()
  const loading = useSelector((state) => state.allowance.loading)

  React.useEffect(()=>{
    dispatch(fetchAllowances())
  },[dispatch])


React.useEffect(()=>{
  window.scrollTo(0,0)
  if(sessionStorage.getItem("adminToken")==null){
    history("/")
  }
},[history])


  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <CustomersComponent users={allowance} loading={loading}/>
        </div>
    
    </div>
  )
}

export default Allowance