import React from 'react'


function EarnitTable(props) {
  return (
        <tr className="w-full">
            <td>{props.task && props.task.startDate.slice(0,16)}</td>
            <td>{props.task.frequency}</td>
            <td className='text-left'>{props.task._id.slice(0,14)}</td>
            <td className='text-left'>{props.task.parentName}</td>
            <td>{props.task.kidName} {props.task.kidLastName}</td>
            <td className="flex mt-2">NGN {props.task.reward}</td>
            <td><div className={props.task.status}>{props.task.status}</div></td>
            
        </tr>
  )
}

export default EarnitTable