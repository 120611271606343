import React from 'react';
import Card from './Card';
import { standardAmountFormat } from '../../utils/amountUtil';

function Overview(props) {
  return (
    <div className="overviewContainer">
      <div>
        <h2>Overview</h2>
        <div className="cardGrid">
          <Card
            link="/customers"
            value={
              props.overview && (props.overview.youngster+props.overview.adult)
            }
            title={'Total users'}
          />
          <Card
            link="/customers"
            value={props.overview && props.overview.youngster}
            title={'Total kids account'}
          />
          <Card
            link="/customers"
            value={props.overview && props.overview.adult}
            title={'Total parents account'}
          />

          <Card
            link="/customers"
            value={
              props.transactions &&
              '₦ ' +
                props.transactions
                  .map(item => item.amount)
                  .reduce((prev, curr) => prev + curr, 0)
                  .toLocaleString('en')
            }
            title={'Total Funds processed'}
          />

          <Card
            link="/kolosave"
            value={
              props.overview && standardAmountFormat(props.overview.kolosave)
            }
            title={'Kolosave'}
          />

          <Card
            link="/transactions"
            value={
              props.paystackDetails.data &&
              '₦ ' +
                parseFloat(
                  props.paystackDetails.data.total_volume / 100
                ).toLocaleString('en')
            }
            title={'Paystack Payments'}
          />
          <Card
            link="/transactions"
            value={
              props.vfdDetails.data &&
              '₦ ' +
                parseFloat(props.vfdDetails.data.accountBalance).toLocaleString(
                  'en'
                )
            }
            title={'Total Wallet Balance'}
          />
          <Card link="/allowance" value={props.allowance && props.allowance.length} title={'Total Allowance'} />


          <Card link="/card" value={0} title={'Total cards issued'} />
          <Card
            link="/transactions"
            value={props.transactions && props.transactions.length}
            title={'Total Transactions'}
          />
          <Card
            link="/earnit"
            value={props.earnit && props.earnit.length}
            title={'Total Tasks'}
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
