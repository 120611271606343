import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  value: "",
  loading: false,
};

export const fetchInvitedParents = createAsyncThunk("invites/fetchInvitedParent", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-invited-parents",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data

    } catch (error) {
      return error;
    }
  });
  
  export const fetchInvitedParent = createSlice({
    name: "invitedparents",
    initialState,
    extraReducers: {
      [fetchInvitedParents.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchInvitedParents.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchInvitedParents.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchInvitedParent.reducer;