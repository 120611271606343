import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  value: "",
  loading: false,
};


export const fetchKolosaves = createAsyncThunk("task/fetchKolosave", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-kolosave",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data
    } catch (error) {
      return error;
    }
  });
  
  export const fetchKolosave = createSlice({
    name: "adminStat",
    initialState,
    extraReducers: {
      [fetchKolosaves.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchKolosaves.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchKolosaves.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchKolosave.reducer;