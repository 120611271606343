import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Settings from "../../components/Settings/Settings"


function Customers() {

  React.useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <Settings/>
        </div>
    
    </div>
  )
}

export default Customers