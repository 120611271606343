import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import { InputGroup, InputLeftElement, Input,Table,Tr,Th,Thead,Tbody } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Tabs from './InviteUserTab';
import CustomersTable from './InviteUserTable';
import CustomerTableLoading from './InviteUserLoading';


function InviteUser(props) {
  const [search,setSearch] = React.useState("")
  const [users,setusers] = React.useState("")
  const [usersLoading] = React.useState([
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'fund',
      amount: '90000',
      accountType:"youngster",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      userType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
  ]);

  const searchValue = (e) =>{
    setSearch(e.target.value)
    if(e.target.value){
    
    setusers(props.users.filter(
      (user) => user.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || 
      user.email.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1  ||
      user.phone.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 
    ))
    console.log(users)
    } else{
      console.log(search)
      return props.users
    }
  }

  const filterAll = () =>{
    setSearch('')

  }

  const filterJoined = () =>{
    setSearch("youngster")
    setusers(props.users.filter(
      (user) => user.status === 'Joined'
    ))
  }

  const filterPending = () =>{
    setSearch("adult")
    setusers(props.users.filter(
      (user) => user.status === undefined
    ))
  }

 

  return (
    <div className="dashboardContainer">
      <PageHeading title="Invited Users" />
      <hr />

      <div className="dashboardContent">
        <Tabs filterJoined={filterJoined} filterAll={filterAll} filterPending={filterPending} />

        <div className="flex space-x-3">
          <InputGroup width={'230px'}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input onChange={(e)=>searchValue(e)}  placeholder="Search" />
          </InputGroup>

          <Input width={'200px'} type={'date'} />
        </div>
      </div>

      <hr />

      <div className="customerTable">
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Phone Number</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {search === ''  && props.users && props.users.slice().reverse().map((user, i) => (
              <CustomersTable user={user} key={i} />
            ))}

            {search && users && users.slice().reverse().map((user, i) => (
              <CustomersTable user={user} key={i} />
            ))}
            </Tbody>

            <Tbody>
            {props.loading && usersLoading.map((user, i) => (
              <CustomerTableLoading user={user} key={i} />
            ))}
            </Tbody>
        </Table>
      </div>
    </div>
  );
}

export default InviteUser;
