import { configureStore } from '@reduxjs/toolkit'
import fetchAdminStat  from './fetchAdminStat'
import fetchAdminTask from './fetchAdminTasks'
import fetchAdminTransaction from './fetchAdminTransactions'
import fetchPaystackBalance from './fetchPaystackBalance'
import fetchVfdBankBalance from './fetchVfdBankBalance'
import fetchEventUser from './fetchEventUser'
import fetchKolosave from './fetchKolosave'
import fetchInvitedParent from './fetchInvitedParent'
import fetchAllowance from './fetchAllowance'
import fetchAdminOverview from './fetchAdminOverview'
import fetchWithdrawal from "./fetchWithdrawal"
import fetchDataExport from './fetchDataExport'



export const store = configureStore({
  reducer: {
    adminStat:fetchAdminStat,
    adminOverview:fetchAdminOverview,
    transactions:fetchAdminTransaction,
    tasks:fetchAdminTask,
    vfdbalance:fetchVfdBankBalance,
    paystackbalance:fetchPaystackBalance,
    events:fetchEventUser,
    invitedparents:fetchInvitedParent,
    kolosave:fetchKolosave,
    allowance:fetchAllowance,
    withdrawals:fetchWithdrawal,
    data:fetchDataExport
  }
 
})