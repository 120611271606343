import React from 'react'

function Tabs(props) {

  const filterAll = () =>{
    document.getElementById("allTasks").classList.add("activeTab")
    document.getElementById("pendingTasks").classList.remove("activeTab")
    document.getElementById("ongoingTasks").classList.remove("activeTab")
    document.getElementById("approvedTasks").classList.remove("activeTab")
    document.getElementById("unapprovedTasks").classList.remove("activeTab")
    props.filterAll();
  }

  const filterPending = () =>{
    document.getElementById("allTasks").classList.remove("activeTab")
    document.getElementById("pendingTasks").classList.add("activeTab")
    document.getElementById("ongoingTasks").classList.remove("activeTab")
    document.getElementById("approvedTasks").classList.remove("activeTab")
    document.getElementById("unapprovedTasks").classList.remove("activeTab")
    props.filterPending();
  }

  const filterOngoing = () =>{
    document.getElementById("allTasks").classList.remove("activeTab")
    document.getElementById("pendingTasks").classList.remove("activeTab")
    document.getElementById("ongoingTasks").classList.add("activeTab")
    document.getElementById("approvedTasks").classList.remove("activeTab")
    document.getElementById("unapprovedTasks").classList.remove("activeTab")
    props.filterOngoing();
  }

  const filterApproved = () =>{
    document.getElementById("allTasks").classList.remove("activeTab")
    document.getElementById("pendingTasks").classList.remove("activeTab")
    document.getElementById("ongoingTasks").classList.remove("activeTab")
    document.getElementById("approvedTasks").classList.add("activeTab")
    document.getElementById("unapprovedTasks").classList.remove("activeTab")
    props.filterApproved();
  }

  const filterUnapproved = () =>{
    document.getElementById("allTasks").classList.remove("activeTab")
    document.getElementById("pendingTasks").classList.remove("activeTab")
    document.getElementById("ongoingTasks").classList.remove("activeTab")
    document.getElementById("approvedTasks").classList.remove("activeTab")
    document.getElementById("unapprovedTasks").classList.add("activeTab")
    props.filterUnapproved();
  }
  
  return (
    <div className="flex mt-2 cursor-pointer">
        <div id="allTasks" onClick={filterAll} className="mr-5 activeTab">All</div>
        <div id="pendingTasks" onClick={filterPending} className="mr-6">Pending</div>
        <div id="ongoingTasks" onClick={filterOngoing} className="mr-6">Ongoing</div>
        <div id="approvedTasks" onClick={filterApproved} className="mr-6">Approved</div>
        <div id="unapprovedTasks" onClick={filterUnapproved} className="mr-6">Unapproved</div>
    </div>
  )
}

export default Tabs