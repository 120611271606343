import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import './Transaction.css';
import { InputGroup, InputLeftElement, Input,Table,Tr,Th,Thead,Tbody } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import TransactionTable from './TransactionTable';
import TransactionsTableLoading from './TransactionsTableLoading';



function Transactions(props) {
  const [search,setSearch] = React.useState("")
  const [transactions,setTransactions] = React.useState("")

  const [transactionsLoading] = React.useState([
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'fund',
      amount: '90000',
      accountType:"youngster",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
  ]);

  const searchValue = (e) =>{
    setSearch(e.target.value)
    if(e.target.value){
    
    setTransactions(props.transactions.filter(
      (transaction) => transaction.senderTag.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || 
      transaction.receiverTag.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
      transaction.transactionId.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 
    ))
    console.log(transactions)
    } else{
      console.log(search)
      return props.transactions
    }
  }

 
  return (
    <div className="dashboardContainer">
      <PageHeading title="Withdrawal Requests" />
      <hr />

      <div className="dashboardContent">
      

        <div className="flex space-x-3">
          <InputGroup >
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input onChange={(e)=>searchValue(e)} placeholder="Search" />
          </InputGroup>

          <Input  type={'date'} />
        </div>
      </div>

      <hr />

      <div>
        <Table size={"sm"}>
          <Thead>
            <Tr>
              <Th>Date/Time</Th>
              <Th>Sender Minietag</Th>
              <Th>Receiver</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {search === '' && props.transactions && props.transactions.map((transaction, i) => (
              <TransactionTable transaction={transaction} key={i} />
            ))}

           {search && transactions && transactions.map((transaction, i) => (
              <TransactionTable transaction={transaction} key={i} />
            ))}
            </Tbody>

            <Tbody>
            {props.loading && transactionsLoading.map((transaction, i) => (
              <TransactionsTableLoading transaction={transaction} key={i} />
            ))}
            </Tbody>
        </Table>
      </div>
    </div>
  );
}

export default Transactions;
