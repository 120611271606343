import React from 'react'

function SettingTab() {
  return (
    <div className="flex mt-2">
        <div className="mr-6 activeTab">Profile</div>
        <div className="mr-6">Password</div>
    </div>
  )
}

export default SettingTab