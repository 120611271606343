import React, { useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import DashboardComponent from '../../components/Dashboard/Dashboard';
import './Dashboard.css';
import { fetchAdminStats } from '../../store/fetchAdminStat';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { fetchAdminTasks } from '../../store/fetchAdminTasks';
import { fetchAdminTransactions } from '../../store/fetchAdminTransactions';
import { fetchVfdBalances } from '../../store/fetchVfdBankBalance';
import { fetchPaystackBalances } from '../../store/fetchPaystackBalance';
import { fetchEventUsers } from '../../store/fetchEventUser';
import { fetchAdminOverviews } from '../../store/fetchAdminOverview';
import { fetchAllowances } from '../../store/fetchAllowance';

function Dashboard() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const stats = useSelector(state => state.adminStat.value);
  const overviews = useSelector(state => state.adminOverview.value);
  const loading = useSelector(state => state.adminStat.loading);
  const earnit = useSelector(state => state.tasks.value);
  const transactions = useSelector(state => state.transactions.value);
  const vfdDetails = useSelector(state => state.vfdbalance.value);
  const paystackDetails = useSelector(state => state.paystackbalance.value);
  const allowance = useSelector(state => state.allowance.value);
  useEffect(() => {
    dispatch(fetchAdminStats());
    dispatch(fetchAdminOverviews());
    dispatch(fetchAdminTasks());
    dispatch(fetchAdminTransactions());
    dispatch(fetchVfdBalances());
    dispatch(fetchPaystackBalances());
    dispatch(fetchEventUsers());
    dispatch(fetchAllowances())
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (sessionStorage.getItem('adminToken') == null) {
      history('/');
    }
  }, [history]);

  return (
    <div className="flex">
      <div className="sideBarWidth">
        <Sidebar />
      </div>
      <div className="dashboardWidth">
        <DashboardComponent
          paystackDetails={paystackDetails}
          stats={stats}
          loading={loading}
          earnit={earnit}
          transactions={transactions}
          vfdDetails={vfdDetails}
          overviews={overviews}
          allowance={allowance}
        />
      </div>
    </div>
  );
}

export default Dashboard;
