import React from 'react'
import LoginComponent from "../../components/Login/Login.js"
import "./Login.css"

function Login() {
  return (
    <div className="loginContainer">
        <LoginComponent />
    </div>
  )
}

export default Login