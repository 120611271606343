import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import './Transaction.css';
import { InputGroup, InputLeftElement, Input,Table,Tr,Th,Thead,Tbody } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Tabs from './TransactionTab';
import TransactionTable from './TransactionTable';
import TransactionsTableLoading from './TransactionsTableLoading';



function Transactions(props) {
  const [search,setSearch] = React.useState("")
  const [transactions,setTransactions] = React.useState("")

  const [transactionsLoading] = React.useState([
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'fund',
      amount: '90000',
      accountType:"youngster",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      transactionType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
  ]);

  const searchValue = (e) =>{
    setSearch(e.target.value)
    if(e.target.value){
    
    setTransactions(props.transactions.filter(
      (transaction) => transaction.senderTag.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || 
      transaction.receiverTag.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
      transaction.transactionId.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 
    ))
    } else{
      console.log(search)
      return props.transactions
    }
  }

  const filterAll = () =>{
    setSearch("")
  }

  const filterDeposit = () =>{
    setSearch("Deposit")
    setTransactions(props.transactions.filter(
      (transaction) => transaction.type.toLowerCase().indexOf('deposit') > -1
    ))
  }

  const filterWithdrawal = () =>{
    setSearch("Withdrawal")
    setTransactions(props.transactions.filter(
      (transaction) => transaction.type.toLowerCase().indexOf('withdrawal') > -1
    ))
  }

  const filterTransfer = () =>{
    setSearch("Transfer")
    setTransactions(props.transactions.filter(
      (transaction) => transaction.type.toLowerCase().indexOf('transfer') > -1
    ))
  }

  const filterSuccessful= () =>{
    setSearch("completed")
    setTransactions(props.transactions.filter(
      (transaction) => transaction.status.toLowerCase().indexOf('completed') > -1
    ))
  }

  const filterPending= () =>{
    setSearch("pending")
    setTransactions(props.transactions.filter(
      (transaction) => transaction.status.toLowerCase().indexOf('pending') > -1
    ))
  }

  const filterFailed= () =>{
    setSearch("failed")
    setTransactions(props.transactions.filter(
      (transaction) => transaction.status.toLowerCase().indexOf('failed') > -1
    ))
  }
 
  return (
    <div className="dashboardContainer">
      <PageHeading title="Transactions" />
      <hr />

      <div className="dashboardContent">
        <Tabs filterAll={filterAll} filterDeposit={filterDeposit} filterWithdrawal={filterWithdrawal} filterTransfer={filterTransfer} filterSuccessful={filterSuccessful} filterPending = {filterPending} filterFailed = {filterFailed}/>

        <div className="flex space-x-3">
          <InputGroup width={'180px'}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input onChange={(e)=>searchValue(e)} placeholder="Search" />
          </InputGroup>

          <Input width={'140px'} type={'date'} />
        </div>
      </div>

      <hr />

      <div>
        <Table size={"sm"}>
          <Thead>
            <Tr>
              <Th>Date/Time</Th>
              <Th>Transaction ID</Th>
              <Th>Sender</Th>
              <Th>Receiver</Th>
              <Th>Transaction Type</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {search === '' && props.transactions && props.transactions.map((transaction, i) => (
              <TransactionTable transaction={transaction} key={i} />
            ))}

           {search && transactions && transactions.map((transaction, i) => (
              <TransactionTable transaction={transaction} key={i} />
            ))}
            </Tbody>

            <Tbody>
            {props.loading && transactionsLoading.map((transaction, i) => (
              <TransactionsTableLoading transaction={transaction} key={i} />
            ))}
            </Tbody>
        </Table>
      </div>
    </div>
  );
}

export default Transactions;
