import React from 'react'
import {useNavigate} from "react-router"

function Card({value,title,link}) {
  const history = useNavigate()


  const goToLink = (link) =>{
    history(link)
  }
  return (
    <div onClick={()=>goToLink(link)} className="cardStyle">
        <h2>{value}</h2>
        <p>{title}</p>
        </div>
  )
}

export default Card