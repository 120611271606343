import React from 'react';
import bell from '../../images/notification.svg';
import profile from '../../images/profile.svg';
import './PageHeading.css';

function PageHeading({ title }) {
  return (
    <div className="dashboardHeading">
      <h1>{title}</h1>
      <div className="flex space-x-8">
        <img width={'20px'} src={bell} alt="" />
        <img width={'20px'} src={profile} alt="" />
      </div>
    </div>
  );
}

export default PageHeading;
