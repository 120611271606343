import { DownloadIcon } from '@chakra-ui/icons';
import { Select,Input, Button } from '@chakra-ui/react';
import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import "./Report.css"



function EarnIt() {
  

  return (
    <div className="dashboardContainer">
      <PageHeading title="Report" />
      <hr />

      <div className="reportCard">
        <div>
        <label>Report Type</label>
        <Select size={"lg"} width={"500px"} placeholder='Select report type'>
            <option>Doc</option>
            <option>Excel</option>
            <option>Pdf</option>
        </Select>
        </div>

        <div className="my-5 flex space-x-9">
        <div>
        <label>Start Date</label>
        <Input size={"lg"} type="date"/>
        </div>

        <div>
        <label>End Date</label>
        <Input size={"lg"} type="date"/>
        </div>
        </div>

        <div className="flex justify-end">
        <Button padding={"30px"} variant={"#005FEB"} rounded={"full"} background={"#005FEB"} color={"white"}><DownloadIcon className="mr-2"/> Download Report</Button>
        </div>
      </div>

      
    </div>
  );
}

export default EarnIt;
