
import React from 'react';


function TransactionsTableLoading() {
  return (
    <tr className="w-full">
      <td>
        <div role="status" className="max-w-sm animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mb-4"></div>
        </div>
      </td>
      <td>
        <div role="status" className="max-w-sm animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-7 mb-4"></div>
        </div>
      </td>
      <td className="text-left">
        <div role="status" className="max-w-sm animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
        </div>
      </td>
      <td className="text-left">
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div></div>
      </td>
      <td>
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div></div>
      </td>
      
      <td>
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10  mb-4"></div></div>
      </td>

      <td>
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mb-4"></div></div>
      </td>
     
     
    </tr>
  );
}

export default TransactionsTableLoading;
