import { Table, Thead, Tr,Th,Tbody } from '@chakra-ui/react'
import React from 'react'
import { dateFormatter } from '../../../utils/dateUtil'
import { standardAmountFormat } from '../../../utils/amountUtil'

function AccountInformationTable(props) {
  return (
    <div id={props.id} className="kolosaveTable">
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Transaction ID</Th>
            <Th>Transaction Type</Th>
            <Th>Amount</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        <Tbody>
        {props.user.transactions.length !== 0 && props.user.transactions.filter((transaction)=>transaction.type==='kolosave').slice().reverse().map((transaction,i)=>(
            <tr key={i}>
            <td>{dateFormatter(transaction.date)}</td>
            <td>{transaction.transactionId && transaction.transactionId.slice(0,12)+"..."}</td>
            <td>{transaction.type}</td>
            <td> {standardAmountFormat(transaction.amount)} </td>
            <td><div className="completed"><li>Completed</li></div></td>
          </tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default AccountInformationTable