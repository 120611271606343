import { Input,Select,Button } from '@chakra-ui/react';
import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import Tabs from "./RolesTab"
import "./Roles.css"


function Dashboard() {
  return (
    <div className="dashboardContainer">
      <PageHeading title="Dashboard" />
      <hr />

      <div className="dashboardContent">
        <Tabs/>
    </div>

    <hr />

    <div className="rolesForm space-y-5">
        <div>
        <label className='font-weight-bold'>Role Name</label>
        <Input size="lg" placeholder='Enter role name' focusBorderColor="#005FEB"/>
        </div>

        <div>
        <label className='font-weight-bold'>Department</label>
        <Input size="lg" placeholder='Enter department' focusBorderColor="#005FEB"/>
        </div>

        <div>
        <label className='font-weight-bold'>Email Address</label>
        <Input size="lg" placeholder='Enter admin email address' focusBorderColor="#005FEB"/>
        </div>

        <div>
        <label className='font-weight-bold'>Admin Roles</label>
        <Select size="lg" placeholder='Select admin role' focusBorderColor="#005FEB">
            <option>user</option>
            <option>manager</option>
            <option>admin</option>
        </Select>
        </div>
        <div className="flex justify-end">
        <Button padding={"25px"} fontSize={"14px"} color="white" rounded="full" background="#005FEB">Create Permission</Button>
        </div>
    </div>

    

    


  

    </div>
  );
}

export default Dashboard;
