import {
  Icon,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { Eye, Delete } from '../Sidebar/Icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import ViewTab from './ViewTab';
import BasicInformationTable from './Tables/BasicInformationTable';
import AccountInformationTable from './Tables/AccountInformationTable';
import KycInformationTable from './Tables/KycTable';
import TransactionHistory from './Tables/TransactionHistory';
import LinkedInformationTable from "./Tables/LinkedInformationTable"
import { dateFormatter } from '../../utils/dateUtil'
import { standardAmountFormat } from '../../utils/amountUtil';

function ViewUser(props) {
  const basicInformation = () => {
    document.getElementById('basic').style.display = 'block';
    document.getElementById('account').style.display = 'none';
    document.getElementById('kyc').style.display = 'none';
    document.getElementById('transaction').style.display = 'none';
    document.getElementById('linked-accounts').style.display = 'none';
  };

  const accountInformation = () => {
    document.getElementById('basic').style.display = 'none';
    document.getElementById('account').style.display = 'block';
    document.getElementById('kyc').style.display = 'none';
    document.getElementById('transaction').style.display = 'none';
    document.getElementById('linked-accounts').style.display = 'none';
  };

  const kycInformation = () => {
    document.getElementById('basic').style.display = 'none';
    document.getElementById('account').style.display = 'none';
    document.getElementById('kyc').style.display = 'block';
    document.getElementById('transaction').style.display = 'none';
    document.getElementById('linked-accounts').style.display = 'none';
  };

  const transactionInformation = () => {
    document.getElementById('basic').style.display = 'none';
    document.getElementById('account').style.display = 'none';
    document.getElementById('kyc').style.display = 'none';
    document.getElementById('transaction').style.display = 'block';
    document.getElementById('linked-accounts').style.display = 'none';
  };

  const linkedInformation = () => {
    document.getElementById('basic').style.display = 'none';
    document.getElementById('account').style.display = 'none';
    document.getElementById('kyc').style.display = 'none';
    document.getElementById('transaction').style.display = 'none';
    document.getElementById('linked-accounts').style.display = 'block';
  };

  return (
    <div>
      <div className="viewTabContainer">
        <ViewTab
          basicInformation={basicInformation}
          accountInformation={accountInformation}
          kycInformation={kycInformation}
          transactionInformation={transactionInformation}
          linkedInformation={linkedInformation}
        />
      </div>
      <hr />

      <div>
        <BasicInformationTable id={'basic'} user={props.user} />
        <AccountInformationTable id={'account'} user={props.user} />
        <KycInformationTable id={'kyc'} user={props.user} />
        <TransactionHistory id={'transaction'} user={props.user} />
        <LinkedInformationTable id={'linked-accounts'} user={props.user} />
      </div>
    </div>
  );
}

function CustomersTable(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewOpen, setViewOpen] = React.useState(false);
  const [actionOpen, setActionOpen] = React.useState(false);
  const [action, setAction] = React.useState('');
  const [btnloading,setBtnloading] = React.useState(false)
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const deleteUser = () => {
    onOpen();
  };

  const viewUser = () => {
    setViewOpen(true);
  };

  const onViewClose = () => {
    setViewOpen(false);
  };

  const confirmAction = action => {
    setAction(action);
    setViewOpen(false);
    setActionOpen(true);
  };

  const onActionClose = () => {
    setActionOpen(false);
  };

  const confirmDelete = () => {
    setBtnloading(true)
    if (action === 'suspended' || action ==='unsuspended') {
      
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/admin/update-status',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('adminToken'),
          ContentType: 'application/json',
        },
        data: {
          action:action === 'unsuspended'? 'unverified':action,
          user:props.user,
        }

      })
        .then(response => {
          setBtnloading(false)
          console.log(response);
          onActionClose()
          Swal.fire({
            icon: 'success',
            title: props.user.firstName + ' has been '+ action+"ed",
            text:
              props.user.firstName +
              ' ' +
              props.user.lastName +
              ' has been ' + action+"ed",
            confirmButtonColor: '#0066f5',
          }).then(()=>{
            window.location.reload()
          })
        })
        .catch(() => {
          onClose();
          Swal.fire({
            icon: 'error',
            title: props.user.firstName + ' not deleted',
            text:
              props.user.firstName +
              ' ' +
              props.user.lastName +
              ' has NOT been suspended',
            confirmButtonColor: '#0066f5',
          });
        });
    }

    if (action === 'Verify' || action ==='Unverify') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/admin/update-status',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('adminToken'),
          ContentType: 'application/json',
        },
        data: {
          action:action === 'Verify'? 'verified':"unverified",
          user:props.user,
        }

      })
        .then(response => {
          setBtnloading(false)
          console.log(response);
          onActionClose()
          Swal.fire({
            icon: 'success',
            title: props.user.firstName + ' has been '+ action+"ed",
            text:
              props.user.firstName +
              ' ' +
              props.user.lastName +
              ' has been ' + action+"ed",
            confirmButtonColor: '#0066f5',
          }).then(()=>{
            window.location.reload()
          })
        })
        .catch(() => {
          onClose();
          Swal.fire({
            icon: 'error',
            title: props.user.firstName + ' not deleted',
            text:
              props.user.firstName +
              ' ' +
              props.user.lastName +
              ' has NOT been suspended',
            confirmButtonColor: '#0066f5',
          });
        });
    } 
  };


  const confirmDeleteUser = () => {
    setBtnloading(true)
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/admin/delete-user",
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('adminToken'),
        ContentType: 'application/json',
      },
      data: {
        action:"deleted",
        userId:props.user._id,
        email:props.user.email,
        firstName:props.user.firstName,
        accountType:props.user.accountType
      }
    }).then((response)=>{
      setBtnloading(false)
      onClose()
      Swal.fire({
        icon:"success",
        title:"Account deleted successfully",
        text:props.user.firstName+" "+props.user.lastName+"'s account has been deleted on MinieMoney",
        confirmButtonColor:"#0066f5"
      }).then(()=>{
        window.location.reload()
      })
    }).catch((error)=>{
      console.log(error)
      setBtnloading(false)
      onClose()
      Swal.fire({
        icon:"error",
        title:"Account not deleted",
        text:props.user.firstName+" "+props.user.lastName+"'s account has  not been deleted",
        confirmButtonColor:"#0066f5"
      })
    })
   
  };

  return (
    <tr>
      <td>
        {props.user.firstName} {props.user.lastName}
      </td>
      <td>{props.user.email.length >= 10?props.user.email.slice(0,10)+"...":props.user.email}</td>
      <td>
        <div className={props.user.accountType + ' w-24'}>
          <li>{props.user.accountType}</li>
        </div>
      </td>
      <td>{dateFormatter(props.user.dateCreated)}</td>
      <td>
        <div>
          {standardAmountFormat(props.user.walletBalance)}
        </div>
      </td>
      <td className="flex space-x-3">
        <Button onClick={viewUser} variant={'unstyled'}>
          <Icon width={'20px'} as={Eye} />
        </Button>
        <Button variant={'unstyled'} onClick={deleteUser}>
          <Icon className="cursor-pointer" width={'10px'} as={Delete} />
        </Button>
      </td>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <Box pos="absolute" top={17} right={407}>
          <ModalCloseButton />
        </Box>
        <ModalContent>
          <ModalHeader>
            <h1>Delete {props.user.firstName}</h1>
          </ModalHeader>

          <ModalBody pb={6}>
            <p>
              Are you sure you want to delete {props.user.firstName}{' '}
              {props.user.lastName}'s account? This deletion is permanent and
              the user's account would not be retrievable
            </p>

            <div className="text-end my-5">
              <Button
                padding={'0px 20px'}
                onClick={onClose}
                color="white"
                rounded="full"
                variant={'black'}
                background="black"
                className="mr-2"
              >
                No,don't delete
              </Button>
              <Button
                onClick={confirmDeleteUser}
                color="white"
                rounded="full"
                variant={'#ff0000'}
                background="#ff0000"
                isLoading={btnloading}
              >
                Yes, Delete
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* modal for view all users details */}
      <Modal isOpen={viewOpen} onClose={onViewClose} size={'5xl'}>
        <ModalOverlay />
        <ModalContent>
          <Box pos="absolute" top={0} right={0}>
            <ModalCloseButton />
          </Box>
          <ModalHeader>
            <h1 className="text-center">User Information</h1>
          </ModalHeader>
          <hr />

          <ViewUser user={props.user} />

          <ModalBody pb={6}>
            <div className="text-end my-5">
              <Button
                padding={'0px 20px'}
                onClick={() => confirmAction(props.user.status ==='suspended'?"unsuspended":"suspended")}
                color="red"
                rounded="full"
                variant={'unstyled'}
                className="mr-2"
              >
                {props.user.status === 'suspended' ? ("Unsuspend "+ props.user.firstName):("Suspend "+ props.user.firstName)}
              </Button>
              <Button
                onClick={() => confirmAction(props.user.status ==='verified'?"Unverify":"Verify")}
                color="white"
                rounded="full"
                variant={'#005FEB'}
                background="#005FEB"
              >
                {props.user.status === 'verified' ? ("Unverify "+ props.user.firstName):("Verify "+ props.user.firstName)}
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={actionOpen} onClose={onActionClose}>
        <ModalOverlay />
        <ModalContent>
          <Box pos="absolute" top={0} right={0}>
            <ModalCloseButton />
          </Box>
          <ModalHeader>
            <h1 className="text-center">
              {' '}
              {(action === 'suspended' || action ==='unsuspended') ? action.slice(0,-2):action } {props.user.firstName}{' '}
            </h1>
          </ModalHeader>
          <hr />

          <ModalBody pb={6}>
            <p className="text-center mt-5">
              Are you sure you want to  {(action === 'suspended' || action ==='unsuspended') ? action.slice(0,-2):action }{' '}
              {props.user.firstName}?
            </p>

            <div className="text-end my-5">
              <Button
                padding={'0px 20px'}
                onClick={onActionClose}
                color="red"
                rounded="full"
                variant={'unstyled'}
                className="mr-2"
              >
                No,close
              </Button>
              <Button
                onClick={confirmDelete}
                color="white"
                rounded="full"
                variant={'#005FEB'}
                background={"#0066f5"}
                isLoading={btnloading}
              >
                Yes,  {(action === 'suspended' || action ==='unsuspended') ? action.slice(0,-2):action } {props.user.firstName}
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </tr>
  );
}

export default CustomersTable;
