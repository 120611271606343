import moment from 'moment';

export function dateFormatter(date) {
  if (typeof date === 'string') {
    const dateObj = moment(date);
    const formattedDate = dateObj.format('MMMM D, YYYY h:mm A');
    if (formattedDate === 'Invalid date') {
      return moment(parseFloat(date)).format('LLL');
    }
    return formattedDate;
  } else {
    return moment(date).format('LLL');
  }
}
