import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  value: "",
  loading: false,
};

export const fetchPaystackBalances = createAsyncThunk("task/fetchPaystackBalances", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-paystack-balance",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data

    } catch (error) {
      return error;
    }
  });
  
  export const fetchPaystackBalance = createSlice({
    name: "paystackbalance",
    initialState,
    extraReducers: {
      [fetchPaystackBalances.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchPaystackBalances.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchPaystackBalances.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchPaystackBalance.reducer;