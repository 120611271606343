import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import React from 'react';
import logo from '../../images/logo.svg';

import "../../pages/Login/Login.css"
import axios from "axios"
import Swal from "sweetalert2"
import { useNavigate } from 'react-router';
import {FiEye,FiEyeOff} from "react-icons/fi"

function Login() {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useNavigate()

  const login = () => {
    if(email === '' || password === ''){
      Swal.fire({
        icon:"warning",
        title:"Enter your details",
        text:"Please enter email and password",
        confirmButtonColor:"#0052ff"
      })
      return
    }


    setLoading(true);
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/admin-login",
      headers:{
        ContentType:"application/json"
      },
      data:{
        email:email,
        password:password
      }
    }).then((response)=>{
      setLoading(false);
      console.log(response)
      if(response.status ===290){
        Swal.fire({
          icon:"error",
          title:"Invalid Login Details",
          text:response.data.errors.password || response.data.errors.email,
          confirmButtonColor:"#0066f5 "
        })
        return
      }
      setLoading(false);
      console.log(response)
      sessionStorage.setItem("adminToken",response.data.token)
      history("/dashboard")
    }).catch((error)=>{
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon:"error",
        title:"Something went wrong",
        text:"You don't have permission to login"
      })
    })
    

  };

  return (
    <div>
    <div className="logoPosition">
      <img className="mx-auto" src={logo} alt="logo" />
    </div>
      <div className="loginFormContainer">

        <h1>Welcome back</h1>
        <p>Signin to your MinieMoney admin account</p>

        <div className="loginForm space-y-5">
        
        <div>
        <label>Email address</label>
        <Input
         size="lg"
          placeholder="Enter your email address"s
          onChange={e => setEmail(e.target.value)}
          focusBorderColor={"#0066f5"}
        />
        </div>


       <div>
        <label>Password</label>
        <InputGroup size="lg">
          <Input
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Enter your password"
            onChange={e => setPassword(e.target.value)}
            focusBorderColor={"#0066f5"}
          />
          <InputRightElement>
            <Button
              
              h="1.75rem"
              size="sm"
              onClick={handleClick}
              variant={"unstyled"}
            >
              {show ? <FiEyeOff size={"20px"}/> : <FiEye size={"20px"}/>}
            </Button>
          </InputRightElement>
        </InputGroup>
        </div>

        <div className="my-3">
          <Button
            rounded={'full'}
            size="lg"
            onClick={login}
            textColor={'white'}
            backgroundColor={'#005FEB'}
            colorScheme={'#005FEB'}
            width={'100%'}
            className="loginButton"
            isLoading={loading}
            fontSize={"14px"}
            padding={"29px 0px"}
          >
            Sign in
          </Button>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
