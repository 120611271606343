import React from 'react'

function Tabs() {
  return (
    <div className="flex mt-2">
        <div className="mr-4 activeTab">Overview</div>
        <div>Chart</div>
    </div>
  )
}

export default Tabs