import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  value: "",
  loading: false,
};

export const fetchEventUsers = createAsyncThunk("events/fetchEventUser", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-event-user",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data

    } catch (error) {
      return error;
    }
  });
  
  export const fetchEventUser = createSlice({
    name: "events",
    initialState,
    extraReducers: {
      [fetchEventUsers.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchEventUsers.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchEventUsers.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchEventUser.reducer;