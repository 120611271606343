import { Table, Thead, Tr,Th,Tbody } from '@chakra-ui/react'
import React from 'react'
import { dateFormatter } from '../../../utils/dateUtil'

function AccountInformationTable(props) {
  return (
    <div id={props.id} className="hiddenForm tableWidth">
      <Table>
        <Thead>
          <Tr>
            <Th>S/N</Th>
            <Th>Information</Th>
            <Th>Inputs</Th>
          </Tr>
        </Thead>

        <Tbody>
          <tr>
            <td>1</td>
            <td>Date Registered</td>
            <td>{dateFormatter(props.user.dateCreated)}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>MinieMoney Account Number</td>
            <td>{props.user.accountNumber}</td>
          </tr>

          <tr>
            <td>3</td>
            <td>MinieMoney Banking Provider</td>
            <td>{props.user.bank}</td>
          </tr>

          <tr>
            <td>4</td>
            <td>MinieMoney ID</td>
            <td>{props.user._id}</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Account Type</td>
            <td>{props.user.accountType}</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Account Status</td>
            <td><div className={props.user.status}><li>{props.user.status}</li></div></td>
          </tr>

        </Tbody>
      </Table>
    </div>
  )
}

export default AccountInformationTable