import React from 'react'

function Tabs(props) {
  const basicInformation = () =>{
    document.getElementById("accountTab").classList.remove("activeTab")
    document.getElementById("kycTab").classList.remove("activeTab")
    document.getElementById("transactionTab").classList.remove("activeTab")
    document.getElementById("basicTab").classList.add("activeTab")
    document.getElementById("linkedAccountTab").classList.remove("activeTab")
    props.basicInformation()
  }

  const accountInformation = () =>{
    document.getElementById("kycTab").classList.remove("activeTab")
    document.getElementById("transactionTab").classList.remove("activeTab")
    document.getElementById("basicTab").classList.remove("activeTab")
    document.getElementById("accountTab").classList.add("activeTab")
    document.getElementById("linkedAccountTab").classList.remove("activeTab")
    props.accountInformation()
  }

  const kycInformation = () =>{
    document.getElementById("transactionTab").classList.remove("activeTab")
    document.getElementById("basicTab").classList.remove("activeTab")
    document.getElementById("accountTab").classList.remove("activeTab")
    document.getElementById("kycTab").classList.add("activeTab")
    document.getElementById("linkedAccountTab").classList.remove("activeTab")
    props.kycInformation()
  }

  const transactionInformation = () =>{
    document.getElementById("basicTab").classList.remove("activeTab")
    document.getElementById("accountTab").classList.remove("activeTab")
    document.getElementById("kycTab").classList.remove("activeTab")
    document.getElementById("transactionTab").classList.add("activeTab")
    document.getElementById("linkedAccountTab").classList.remove("activeTab")
    props.transactionInformation()
  }

  const linkedInformation = () =>{
    document.getElementById("basicTab").classList.remove("activeTab")
    document.getElementById("accountTab").classList.remove("activeTab")
    document.getElementById("kycTab").classList.remove("activeTab")
    document.getElementById("transactionTab").classList.remove("activeTab")
    document.getElementById("linkedAccountTab").classList.add("activeTab")
    props.linkedInformation()
  }


  return (
    <div className="flex mt-2 cursor-pointer">
        <div id="basicTab" onClick={()=>basicInformation()} className="mr-5 activeTab">Basic Information</div>
        <div id="accountTab" onClick={()=>accountInformation()}  className="mr-6">Account Information</div>
        <div id="kycTab" onClick={()=>kycInformation()}  className="mr-6">KYC Documents</div>
        <div id="transactionTab" onClick={()=>transactionInformation()}  className="mr-6">Transaction History</div>
        <div id="linkedAccountTab" onClick={()=>linkedInformation()}  className="mr-6">Linked Accounts</div>
        
    </div>
  )
}

export default Tabs