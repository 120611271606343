import {
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import React from 'react';
// import ViewTab from './ViewTab';
import BasicInformationTable from './Tables/BasicInformationTable';
import { standardAmountFormat } from '../../utils/amountUtil';

function ViewUser(props) {




  return (
    <div>
      <div className="viewTabContainer">
        {/* <ViewTab
          basicInformation={basicInformation}
        /> */}
      </div>

      <div>
        <BasicInformationTable id={'basic'} user={props.user} />
      </div>
    </div>
  );
}

function CustomersTable(props) {
  const [viewOpen, setViewOpen] = React.useState(false);


  const viewUser = () => {
    setViewOpen(true);
  };

  const onViewClose = () => {
    setViewOpen(false);
  };





  return (
    <tr>
      <td>
        {props.user.firstName} {props.user.lastName}
      </td>
      <td>{standardAmountFormat(props.user.koloBalance)}</td>
      <td>
      {standardAmountFormat(props.user.walletBalance)}
      </td>
      <td>{standardAmountFormat(props.user.koloInterest)}</td>
      <td>
      <div className={props.user.accountType + ' w-24'}>
          <li>{props.user.accountType}</li>
        </div>
      </td>
      <td className="mt-3">
        <Button className="outlined" fontSize={"12px"} padding={"8px"} size={"sm"} rounded={"full"} onClick={viewUser} variant={'unstyled'}>
          View Transactions
        </Button>
      </td>

      

      {/* modal for view all users details */}
      <Modal isOpen={viewOpen} onClose={onViewClose} size={'5xl'}>
        <ModalOverlay />
        <ModalContent>
          <Box pos="absolute" top={0} right={0}>
            <ModalCloseButton />
          </Box>
          <ModalHeader>
            <h1 className="text-center">Transactions</h1>
          </ModalHeader>
          <hr />

          <ViewUser user={props.user} />

          <ModalBody pb={6}>
            
          </ModalBody>
        </ModalContent>
      </Modal>

      
    </tr>
  );
}

export default CustomersTable;
