import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import './EarnIt.css';
import { InputGroup, InputLeftElement, Input,Table,Tr,Th,Thead,Tbody } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Tabs from './EarnItTab';
import EarnitTable from './EarnItTable';
import EarnitTableLoading from './EarnitTableLoading';


function EarnIt(props) {
  const [search,setSearch] = React.useState("")
  const [tasks,setTasks] = React.useState([])
  const [tasksLoading] = React.useState([
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      earnitType: 'fund',
      amount: '90000',
      accountType:"youngster",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      earnitType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      earnitType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
    {
      date: '23-03-2022/09:24:34',
      _id: '84758275859',
      accountName: 'Gbolahan Fan..',
      earnitType: 'withdrawal',
      amount: '90000',
      accountType:"adult",
      status:"verified",
      startDate:"dsdkkjkskdjkjksjksjkkjk",
      frequency:"daily",
      parentName:"daily",
      kidName:"kidss"
    },
  ]);

  const searchValue = (e) =>{
    
    setSearch(e.target.value)
    if(e.target.value){
    setTasks(props.earnit.filter(
      (earnit) => earnit.parentName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || 
      earnit.kidName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
      earnit._id.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 
    ))
    console.log(props.earnit)
    } else{
      console.log(search)
      return props.earnit
    }
  }

  const filterAll = () =>{
    setSearch("")
  }

  const filterPending = () =>{
    setSearch("Pending")
    setTasks(props.earnit.filter(
      (task) => task.status.toLowerCase().indexOf('pending') > -1
    ))
  }

  const filterOngoing = () =>{
    setSearch("Ongoing")
    setTasks(props.earnit.filter(
      (task) => task.status.toLowerCase().indexOf('ongoing') > -1
    ))
  }

  const filterApproved = () =>{
    setSearch("approved")
    setTasks(props.earnit.filter(
      (task) => task.status.toLowerCase().indexOf('approved') > -1
    ))
  }

  const filterUnapproved = () =>{
    setSearch("unapproved")
    setTasks(props.earnit.filter(
      (task) => task.status.toLowerCase().indexOf('unapproved') > -1
    ))
  }

  return (
    <div className="dashboardContainer">
      <PageHeading title="EarniT" />
      <hr />

      <div className="dashboardContent">
        <Tabs filterAll={filterAll} filterPending={filterPending} filterOngoing={filterOngoing} filterApproved={filterApproved} filterUnapproved={filterUnapproved}/>

        <div className="flex space-x-3">
          <InputGroup width={'180px'}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input onChange={(e)=>searchValue(e)} placeholder="Search" />
          </InputGroup>

          <Input  width={'140px'} type={'date'} />
        </div>
      </div>

      <hr />

      <div className="earnitTable">
        <Table>
          <Thead width={"100%"}>
            <Tr>
              <Th>Start Date/Time</Th>
              <Th>Task Frequency</Th>
              <Th>Task ID</Th>
              <Th>Task Creator</Th>
              <Th>Task For</Th>
              <Th>Task Reward</Th>
              <Th>Status</Th>
             
            </Tr>
          </Thead>
          <Tbody>
            {search === '' && props.earnit && props.earnit.map((earnit, i) => (
              <EarnitTable task={earnit} key={i} />
            ))}

            {search && tasks && tasks.map((earnit, i) => (
              <EarnitTable task={earnit} key={i} />
            ))}


            </Tbody>


            <Tbody>
            {props.loading && tasksLoading.map((earnit, i) => (
              <EarnitTableLoading task={earnit} key={i} />
            ))}
            </Tbody>
            
        </Table>
      </div>
    </div>
  );
}

export default EarnIt;
