import React from 'react';

function Tabs(props) {
  const filterActive = () => {
    document.getElementById("all").classList.remove("activeTab")
    document.getElementById("inactive").classList.remove("activeTab")
    document.getElementById("active").classList.add("activeTab")
    props.filterActive();
  };
  const filterInActive = () => {
    document.getElementById("all").classList.remove("activeTab")
    document.getElementById("active").classList.remove("activeTab")
    document.getElementById("inactive").classList.add("activeTab")
    props.filterInActive();
  };

  const filterAll = () => {
    document.getElementById("active").classList.remove("activeTab")
    document.getElementById("inactive").classList.remove("activeTab")
    document.getElementById("all").classList.add("activeTab")
    props.filterAll();
  };
  return (
    <div className="flex mt-2 cursor-pointer">
      <div id="all" onClick={filterAll} className="mr-5 activeTab">
        All
      </div>
      <div id="active" onClick={()=>filterActive()} className="mr-5">
        Active
      </div>
      <div id="inactive" onClick={()=>filterInActive()}>Inactive</div>
    </div>
  );
}

export default Tabs;
