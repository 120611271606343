import { Icon } from '@chakra-ui/react';
import React from 'react';
import { Eye, Delete } from '../Sidebar/Icons';


function EarnitTableLoading() {
  return (
    <tr className="w-full">
      <td>
        <div role="status" className="max-w-sm animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-8 mb-4"></div>
        </div>
      </td>
      <td>
        <div role="status" className="max-w-sm animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
        </div>
      </td>
      <td className="text-left">
        <div role="status" className="max-w-sm animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
        </div>
      </td>
      <td className="text-left">
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div></div>
      </td>
      <td>
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div></div>
      </td>
      <td className="flex mt-2">
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div></div>
      </td>
      <td>
      <div role="status" className="max-w-sm animate-pulse">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div></div>
      </td>
      <td className="flex">
        <Icon as={Eye} />
        <Icon as={Delete} />
      </td>
    </tr>
  );
}

export default EarnitTableLoading;
