import { Table, Thead, Tr,Th,Tbody, Spinner } from '@chakra-ui/react'
import React, { useState,useEffect } from 'react'
import axios from 'axios'
import { dateFormatter } from '../../../utils/dateUtil'
import { standardAmountFormat } from '../../../utils/amountUtil'

function AccountInformationTable(props) {
  const [loading, setLoading] = useState(false)
  const [parent,setParent] = useState([])
  useEffect(()=>{

      setLoading(true)
      axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin/fetch-parents?email="+props.user.email,
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
          ContentType:"application/json",
          Accept:"*/*"
        }
      }).then((response)=>{
        console.log(response)
        setParent(response.data.data)
        setLoading(false)
      }).catch((error)=>{
        console.log(error)
        setParent([])
      })
   // eslint-disable-next-line 
  },[])
  return (
    <div id={props.id} className="hiddenForm tableWidth">
      <Table>
        <Thead>
          <Tr>
            <Th>S/N</Th>
            <Th>Account Name</Th>
            <Th>Email Address</Th>
            <Th>{props.user.accountType ==='adult'?"Relationship":"Relationship"}</Th>
            <Th>{props.user.accountType ==='adult'?"Wallet Balance":"Date Registered"}</Th>
          </Tr>
        </Thead>

        {props.user.accountType ==='adult' && <Tbody>
          {props.user.youngsters&& props.user.youngsters.map((youngster,i)=>(
            <tr key={i}>
              <td>{i+1}</td>
              <td>{youngster.firstName} {youngster.lastName}</td>
              <td>{youngster.email}</td>
              <td className={'youngster w-24'}>youngster</td>
              <td>{standardAmountFormat(youngster.walletBalance)}</td>
            </tr>
          ))}
        </Tbody>}

        {props.user.accountType ==='youngster' && <Tbody>
          {loading &&<Spinner />}
          {parent&& parent.map((parent,i)=>(
            <tr key={i}>
              <td>{i+1}</td>
              <td>{parent.firstName} {parent.lastName}</td>
              <td>{parent.email}</td>
              <td className={'youngster w-24'}>{parent && parent.youngsters.filter((youngster)=>youngster.email===props.user.email)[0].relationship}</td>
              <td>{dateFormatter(parent.dateCreated)}</td>
            </tr>
          ))}
        </Tbody>}
      </Table>
    </div>
  )
}

export default AccountInformationTable