import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  value: "",
  loading: false,
};


export const fetchAdminTransactions = createAsyncThunk("task/fetchAdminTransactions", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-transactions",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data.transactions.slice().reverse().slice(0,100)

    } catch (error) {
      return error;
    }
  });
  
  export const fetchAdminTransaction = createSlice({
    name: "transactions",
    initialState,
    extraReducers: {
      [fetchAdminTransactions.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchAdminTransactions.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchAdminTransactions.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchAdminTransaction.reducer;