import React from 'react'
import { dateFormatter } from '../../utils/dateUtil'
import { standardAmountFormat } from '../../utils/amountUtil'

function CustomersTable(props) {
  return (
        <tr style={{width:"20px"}}>
            <td>{dateFormatter(props.transaction.date)}</td>
            <td>{props.transaction.transactionId.slice(0,16)}...</td>
            <td>{props.transaction.senderTag && props.transaction.senderTag.length >= 10?props.transaction.senderTag.slice(0,10)+"...":props.transaction.senderTag}</td>
            <td style={{width:"3px"}}> {props.transaction.receiverTag.slice(0,10)}</td>
            <td style={{width:"2px"}}>{props.transaction.type}</td>
            <td> {standardAmountFormat(props.transaction.amount)} </td>
            <td><div className={props.transaction.status.toLowerCase()}><span className={props.transaction.status.toLowerCase() ==='completed'?"circleSuccess mr-2":"circleDeclined mr-2"}></span> {props.transaction.status}</div></td>
            
        </tr>
  )
}

export default CustomersTable