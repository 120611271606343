import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import InviteUserComponent from "../../components/InvitedParent/InviteUser"
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router"
import { fetchInvitedParents } from '../../store/fetchInvitedParent'


function InvitedParent() {
  const invitedparents = useSelector((state) => state.invitedparents.value.invitedParents)
  const dispatch = useDispatch()
  const history = useNavigate()
  const loading = useSelector((state) => state.invitedparents.loading)

  React.useEffect(()=>{
    dispatch(fetchInvitedParents())
  },[dispatch])


React.useEffect(()=>{
  window.scrollTo(0,0)
  if(sessionStorage.getItem("adminToken")==null){
    history("/")
  }
},[history])


  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <InviteUserComponent users={invitedparents} loading={loading}/>
        </div>
    
    </div>
  )
}

export default InvitedParent