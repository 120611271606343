import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import TransactionComponent from "../../components/Transactions/Transactions"
import { fetchAdminTransactions } from '../../store/fetchAdminTransactions'
import {useDispatch, useSelector} from "react-redux"


function Customers() {
  const dispatch = useDispatch()
  const transactions = useSelector((state) => state.transactions.value)
  const loading = useSelector((state) => state.transactions.loading)
  React.useEffect(()=>{
    window.scrollTo(0,0)
    dispatch(fetchAdminTransactions())
  },[dispatch])

  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <TransactionComponent transactions={transactions} loading={loading}/>
        </div>
    
    </div>
  )
}

export default Customers