import { Input,Button } from '@chakra-ui/react';
import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import Tabs from "./SettingTab"



function Dashboard() {
  return (
    <div className="dashboardContainer">
      <PageHeading title="Dashboard" />
      <hr />

      <div className="dashboardContent">
        <Tabs/>
    </div>

    <hr />

    <div className="rolesForm space-y-5">
        <div>
        <label className='font-weight-bold'>First Name</label>
        <Input size="lg" value={"MinieMoney Admin"} focusBorderColor="#005FEB"/>
        </div>

        <div>
        <label className='font-weight-bold'>Last Name</label>
        <Input size="lg" value={"MinieMoney"} focusBorderColor="#005FEB"/>
        </div>

        <div>
        <label className='font-weight-bold'>Email Address</label>
        <Input size="lg" value={"admin@miniemoney.com"} focusBorderColor="#005FEB"/>
        </div>

       
        <div className="flex justify-end">
        <Button variant="#005FEB" padding={"25px"} fontSize={"14px"} color="white" rounded="full" background="#005FEB">Update Profile</Button>
        </div>
    </div>

    

    


  

    </div>
  );
}

export default Dashboard;
