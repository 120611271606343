import React, { useState } from 'react';
import { dateFormatter } from '../../utils/dateUtil';
import { standardAmountFormat } from '../../utils/amountUtil';
import PrimaryBtn from '../Buttons/PrimaryBtn';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Button,
  useDisclosure,
  ModalContent,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';

function CustomersTable(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [btnloading, setBtnloading] = useState(false);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [action, setAction] = useState('');
  const [id, setId] = useState('');
  const [transaction, setTransaction] = useState('');

  const approveTransaction = transaction => {
    setId(transaction._id);
    setTransaction(transaction);
    setAction('Approve Withdrawal Request');
    onOpen();
  };

  const declineTransaction = transaction => {
    console.log(transaction)
    setId(transaction._id);
    setTransaction(transaction);
    setAction('Decline Withdrawal Request');
    onOpen();
  };


  const confirmApproval = () =>{
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/admin/approve-withdrawal',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('adminToken'),
        ContentType: 'application/json',
      },
      data: {
        transaction: transaction,
      },
    })
      .then(response => {
        onClose()
        setBtnloading(false);
        console.log(response);
        Swal.fire({
          icon:"success",
          title:"Withdrawal approved successfully",
          text:"You have successfully approved this withdrawal request",
          confirmButtonColor:"#0066f5"
        }).then((result)=>{
          window.location.reload()
          if(result.isConfirmed){
            window.location.reload()
          }
        })
      })
      .catch(error => {
        setBtnloading(false);
        console.log(error);
        Swal.fire({
          icon:"error",
          title:"Something went wrong!",
          text:"Please try again later",
          confirmButtonColor:"#0066f5"
        })
      });
  }

  const confirmDecline = () =>{
    console.log(transaction)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/admin/decline-withdrawal',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('adminToken'),
        ContentType: 'application/json',
      },
      data: {
        transaction: transaction,
      },
    })
      .then(() => {
        onClose()
        setBtnloading(false);
        Swal.fire({
          icon:"success",
          title:"Withdrawal declined successfully",
          text:"You have successfully declined this transaction",
          confirmButtonColor:"#0066f5"
        }).then((result)=>{
          window.location.reload()
          if(result.isConfirmed){
            window.location.reload()
          }
        })
      })
      .catch(error => {
        setBtnloading(false);
        console.log(error);
        Swal.fire({
          icon:"error",
          title:"Something went wrong!",
          text:"Please try again later",
          confirmButtonColor:"#0066f5"
        })
      });
  }

  const confirmAction = () => {
    console.log(id);
    setBtnloading(true);
    if (action === 'Approve Withdrawal Request') {
      confirmApproval()
    }
    else{
      confirmDecline()
    }
  };

  return (
    <tr style={{ width: '20px' }}>
      <td>{dateFormatter(props.transaction.date)}</td>
      <td>
        {props.transaction.senderTag.length >= 10
          ? props.transaction.senderTag.slice(0, 10) + '...'
          : props.transaction.senderTag}
      </td>
      <td style={{ width: '3px' }}>
        {' '}
        {props.transaction.accountNumber}({props.transaction.bankName})
      </td>
      <td> {standardAmountFormat(props.transaction.amount)} </td>
      <td>
        <div className={props.transaction.status.toLowerCase()}>
          <span
            className={
              props.transaction.status.toLowerCase() === 'approved'
                ? 'circleSuccess mr-2'
                : 'circleDeclined mr-2'
            }
          ></span>{' '}
          {props.transaction.status}
        </div>
      </td>
      {props.transaction.status ==="Pending" && <td>
        <PrimaryBtn
          onClick={() => approveTransaction(props.transaction)}
          title={'Approve'}
          rounded={'full'}
          color={'white'}
          bgcolor={'#00aa4f'}
        />
        <div className="my-3"></div>
        <PrimaryBtn
          onClick={() => declineTransaction(props.transaction)}
          title={'Decline'}
          rounded={'full'}
          color={'#eb3f3f'}
          variant={'outline'}
          border={'#eb3f3f'}
        />
      </td>}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <Box pos="absolute" top={17} right={407}>
          <ModalCloseButton />
        </Box>
        <ModalContent>
          <ModalHeader>
            <h1>{action}</h1>
          </ModalHeader>

          <ModalBody pb={6}>
            <p>
              Are you sure you want to {action.toLowerCase()} this withdrawal
              request? Please thoroughly check the user's transaction and
              confirm that they are not malicious.
            </p>

            <div className="text-end my-5">
              <Button
                padding={'0px 20px'}
                onClick={onClose}
                color="white"
                rounded="full"
                variant={'black'}
                background="black"
                className="mr-2"
              >
                No
              </Button>
              <Button
                onClick={confirmAction}
                color="white"
                rounded="full"
                variant={'#ff0000'}
                background="#ff0000"
                isLoading={btnloading}
              >
                Confirm
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </tr>
  );
}

export default CustomersTable;
