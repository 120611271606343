import React from 'react';
import logo from '../../images/logo.svg';
import { Icon, Text, Collapse } from '@chakra-ui/react';
// import { useNavigate } from 'react-router'
import {
  Dashboard,
  DashboardActive,
  Users,
  UsersActive,
  Transactions,
  TransactionsActive,
  EarnIt,
  EarnItActive,
  Card,
  CardActive,
  Roles,
  RolesActive,
  Setting,
  SettingActive,
  Logout,
  Report,
  ReportActive,
  Withdraw,
  WithdrawActive,
  InviteUser,
  InviteUserActive,
  MinieSavings,
  Kolosave,
  KolosaveActive,
} from './Icons';
import './SideBar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

export function NavItem({ icon, title, active, link }) {
  // const history = useNavigate()
  return (
    <NavLink to={link}>
      <div className={'navItem ' + active}>
        <Icon padding={'9px 0px 0px 0px'} as={icon}></Icon>
        <Text fontSize={'14px'} margin={'-2px 9px'}>
          {title}
        </Text>
      </div>
    </NavLink>
  );
}

export function NavMenuItem({ icon, title }) {
  const { isOpen, onToggle } = useDisclosure();
  const url = window.location.pathname;
  // const history = useNavigate()
  return (
    <>
      <NavLink style={{ color: 'black' }} onClick={onToggle}>
        <div className={'navItem'}>
          <Icon padding={'9px 0px 0px 0px'} as={icon}></Icon>
          <Text fontSize={'14px'} margin={'-2px 9px'}>
            {title}
          </Text>
          {isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </div>
      </NavLink>

      <Collapse in={isOpen} animateOpacity>
        <Box ml="8">
          <NavItem
            link={'/kolosave'}
            active={url === '/kolosave' ? 'active' : ''}
            icon={url === '/kolosave' ? KolosaveActive : Kolosave}
            title={'Kolosave'}
          />
        </Box>
      </Collapse>
    </>
  );
}

function Sidebar() {
  const url = window.location.pathname;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useNavigate();

  const logOut = () => {
    sessionStorage.removeItem('adminToken');
    history('/');
  };

  return (
    <div className="sideBarContainer">
      <img src={logo} alt="" />
      <div className="navItemContainer">
        <NavItem
          link={'/dashboard'}
          active={url === '/dashboard' ? 'active' : ''}
          icon={url === '/dashboard' ? DashboardActive : Dashboard}
          title={'Dashboard'}
        />
        <NavItem
          link={'/customers'}
          active={url === '/customers' ? 'active' : ''}
          icon={url === '/customers' ? UsersActive : Users}
          title={'Customer Management'}
        />
        <NavItem
          link={'/transactions'}
          active={url === '/transactions' ? 'active' : ''}
          icon={url === '/transactions' ? TransactionsActive : Transactions}
          title={'Transactions'}
        />
        <NavItem
          link={'/earnit'}
          active={url === '/earnit' ? 'active' : ''}
          icon={url === '/earnit' ? EarnItActive : EarnIt}
          title={'EarnIt'}
        />

        <NavMenuItem
          link={'/#'}
          active={url === '/kolosave' ? 'active' : ''}
          icon={url === '/kolosave' ? MinieSavings : MinieSavings}
          title={'MinieSavings'}
        />

        <NavItem
          link={'/allowance'}
          active={url === '/allowance' ? 'active' : ''}
          icon={url === '/allowance' ? CardActive : Card}
          title={'Allowance'}
        />

        <NavItem
          link={'/invite-users'}
          active={url === '/invite-users' ? 'active' : ''}
          icon={url === '/invite-users' ? InviteUserActive : InviteUser}
          title={'Invited Users'}
        />

        <NavItem
          link={'/invite-parents'}
          active={url === '/invite-parents' ? 'active' : ''}
          icon={url === '/invite-parents' ? InviteUserActive : InviteUser}
          title={'Invited Parents'}
        />

        <NavItem
          link={'/card'}
          active={url === '/card' ? 'active' : ''}
          icon={url === '/card' ? CardActive : Card}
          title={'Card Request'}
        />

        <NavItem
          link={'/withdrawal'}
          active={url === '/withdrawal' ? 'active' : ''}
          icon={url === '/withdrawal' ? WithdrawActive : Withdraw}
          title={'Withdrawal Request'}
        />
        <NavItem
          link={'/roles'}
          active={url === '/roles' ? 'active' : ''}
          icon={url === '/roles' ? RolesActive : Roles}
          title={'Roles & Permission'}
        />
        <NavItem
          link={'/report'}
          active={url === '/report' ? 'active' : ''}
          icon={url === '/report' ? ReportActive : Report}
          title={'Report'}
        />

        <NavItem
          link={'/settings'}
          active={url === '/settings' ? 'active' : ''}
          icon={url === '/settings' ? SettingActive : Setting}
          title={'Settings'}
        />
      </div>

      <div onClick={() => onOpen()} className="logOut">
        <div className={'navItem'}>
          <Icon padding={'9px 0px 0px 0px'} as={Logout}></Icon>
          <Text fontSize={'14px'} margin={'-2px 9px'}>
            Sign Out
          </Text>
        </div>
      </div>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <Box pos="absolute" top={17} right={407}>
          <ModalCloseButton />
        </Box>
        <ModalContent>
          <ModalHeader>
            <h1 className="text-center">Log Out</h1>
          </ModalHeader>

          <ModalBody pb={6}>
            <p className="text-center">Are you sure you want to logout?</p>

            <div className="text-end my-5">
              <Button
                padding={'0px 20px'}
                onClick={onClose}
                color="#005FEB"
                rounded="full"
                variant={'unstyled'}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button
                onClick={logOut}
                color="white"
                rounded="full"
                variant={'#005FEB'}
                background="#005FEB"
              >
                Yes, Logout
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Sidebar;
