import React from 'react';

const Pagination = ({ overviews, handlePageClick, page }) => {
  const pagination = Math.ceil((overviews.adult + overviews.youngster) / 20);

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <button
          className={page === i ? 'page active-page' : 'page'}
          key={i}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return <div className="scrollPagination">{renderPages()}</div>;
};

export default Pagination;
