import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const currentPage = JSON.parse(sessionStorage.getItem("page"))

const initialState = {
  value: "",
  loading: false,
  page: currentPage ? currentPage:1,
  limit:20,
  search:""
};




export const fetchAdminStats = createAsyncThunk("task/fetchAdminStat", async (_,{getState}) => {
    const state = getState()
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/admin-fetch?page="+state.adminStat.page+"&limit="+state.adminStat.limit+"&search="+state.adminStat.search,
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data
    } catch (error) {
      return error;
    }
  });
  
  export const fetchAdminStat = createSlice({
    name: "adminStat",
    initialState,
    reducers: {
      setPage:(state, action) => {
        state.loading = true
        sessionStorage.setItem("page",JSON.stringify(action.payload))
        state.page = action.payload;
      },

      setLimit:(state, action) => {
        console.log(action.payload)
        state.limit = action.payload;
      },

      setSearchUsers:(state,action) => {
        state.search = action.payload
      }
    },
    extraReducers: {
      [fetchAdminStats.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchAdminStats.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchAdminStats.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  

  export const { setPage,setLimit,setSearchUsers} = fetchAdminStat.actions;
  export default fetchAdminStat.reducer;