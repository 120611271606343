import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import WithdrawalComponent from "../../components/Withdrawal/Transactions"
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router"
import { fetchAdminWithdrawals } from '../../store/fetchWithdrawal'


function Withdrawal() {
  const withdrawals = useSelector((state) => state.withdrawals.value)
  const dispatch = useDispatch()
  const history = useNavigate()
  const loading = useSelector((state) => state.withdrawals.loading)

  React.useEffect(()=>{
    dispatch(fetchAdminWithdrawals())
  },[dispatch])


React.useEffect(()=>{
  window.scrollTo(0,0)
  if(sessionStorage.getItem("adminToken")==null){
    history("/")
  }
},[history])



  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <WithdrawalComponent transactions={withdrawals} loading={loading}/>
        </div>
    
    </div>
  )
}

export default Withdrawal