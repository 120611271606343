import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'




function Cards() {

  React.useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div className="flex">
        <div className="sideBarWidth">
        <Sidebar/>
        </div>
        <div className="dashboardWidth">
            <div className="shadow-lg max-w-lg mx-auto px-5 py-12 text-center mt-9">
                <h1 className="text-2xl font-bold">Card feature is coming soon</h1>
                <p>This page will manage the card features once it is available</p>

            </div>
        </div>
    
    </div>
  )
}

export default Cards